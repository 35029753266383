// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  Url: "https://servitzcoreapi02.fritz.co.il/api/",
  // Url : "https://localhost:44367/api/",
  //baseUrl: "https://servitzcoreapi.fritz.co.il/",
  baseUrl: "https://servitzcoreapi02.fritz.co.il/",
  ELBIT_ID: "36540e93-8d8a-47d2-b65d-c89839d80560",
  ALIGN_TECHNOLOGY_ID: "b17c5e81-508d-415a-9048-1e9332aaabcb",
  apiKey: "AIzaSyCFhg2vpalqU8cyS53L5Gkg-F-u_hqmJjc",
  authDomain: "servitzdmznew.firebaseapp.com",
  projectId: "servitzdmznew",
  storageBucket: "servitzdmznew.appspot.com",
  messagingSenderId: "693578481009",
  appId: "1:693578481009:web:2b413c03cdbf5da34d0ad0",
  measurementId: "G-MXM5W28MV2",
  //
  //baseUrl : "https://localhost:44367/",
  //baseUrl : "http://90.0.0.28:98/",
  GetServitzHomeUrl() {
    return "https://servitzhome.fritz.co.il/";
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

