import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { MDBBootstrapModule } from "angular-bootstrap-md";
// import { PickerModule } from "@ctrl/ngx-emoji-mart";
// import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
 import {
//   MatAutocompleteModule,
   MatButtonModule,
//   MatButtonToggleModule,
//   MatCardModule,
//   MatCheckboxModule,
//   MatChipsModule,
//   MatDatepickerModule,
   MatDialogModule,
   MatDialogRef,
//   MatExpansionModule,
//   MatGridListModule,
   MatIconModule,
//   MatInputModule,
//   MatListModule,
//   MatMenuModule,
//   MatNativeDateModule,
//   MatPaginatorModule,
//   MatProgressBarModule,
//   MatProgressSpinnerModule,
//   MatRadioModule,
//   MatSelectModule,
//   MatSidenavModule,
//   MatSliderModule,
//   MatSlideToggleModule,
   MatSnackBarModule
//   MatSortModule,
//   MatStepperModule,
//   MatTableModule,
//   MatTabsModule,
//   MatToolbarModule,
//   MatTooltipModule,
//     MatFormFieldModule,
 } from "@angular/material";
// import { MatTableFilterModule } from 'mat-table-filter';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
//import { MatFileUploadModule } from "angular-material-fileupload";

//import { CountdownModule } from "ngx-countdown";
//import { AvatarModule } from "ngx-avatar";
import { ReportsService } from "./Services/reports.service";
// import { CountdownTimerModule } from "ngx-countdown-timer";
import { DatePipe } from "@angular/common";
import { UploadDownloadService } from "./Services/upload-download.service";
// import { FileManagerComponent } from "./components/files/file-manager/file-manager.component";
// import { UploadComponent } from "./components/files/upload/upload.component";
// import { DownloadComponent } from "./components/files/download/download.component";
//import { LevanonReportsService } from "./Services/levanon-reports.service";
//import { ReportGeneratorComponent } from "./components/report-generator/report-generator.component";
//import { GroupByPipe } from "./pipe/group-by.pipe";
import { FilterPipeModule } from "ngx-filter-pipe";
//import { SpecialCharacterDirective } from "./pipe/special-character.directive";
//import { DndModule } from "ngx-drag-drop";
import { InputsModule, WavesModule } from "angular-bootstrap-md";
//import { ReportViewerComponent } from "./components/report-viewer/report-viewer.component";
//import { ReportTableComponent } from "./components/report-table/report-table.component";
//import { ReportChartsComponent } from "./components/report-charts/report-charts.component";
//import { UpcomingToLatestPipe } from "./pipe/upcomingToLatest";
import { LogService } from "./Services/log.service";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CatchErrorInterceptor } from './providers/catch-error.Interceptor';
import { MessageComponent } from "./components/message/message.component";
import { CookieService } from "ngx-cookie-service";
//import { NewEmailListComponent } from "./components/orgs/new-email-list/new-email-list.component";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    MessageComponent
  ],
  imports: [
    
    // PickerModule,
    // InputsModule,
    WavesModule,
    BrowserModule,
    //CountdownModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MatAutocompleteModule,
    MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // //MatBadgeModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatDatepickerModule,
    // SatDatepickerModule, 
    // SatNativeDateModule,
     MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
     MatIconModule,
    // MatInputModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // //MatRippleModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
     MatSnackBarModule,
    // MatSortModule,
    // MatStepperModule,
    // MatTableModule,
    // MatTableFilterModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    //MatFileUploadModule,
    HttpClientModule,
    //AvatarModule,
    FilterPipeModule,
    // MatFormFieldModule,
    //DndModule,
    //CountdownTimerModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    LogService,
    CookieService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: HTTP_INTERCEPTORS, useClass: CatchErrorInterceptor, multi: true }

  ],
  entryComponents:[MessageComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
