
import { LogI } from '../Models/log.model';
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';



@Injectable({

  providedIn: "root",

})

export class LogService {
  readonly url = environment.Url + "log/";
  constructor(
    private http: HttpClient,
  ) { }

  Error(logmodel: LogI) {
    logmodel.data = JSON.stringify(logmodel.data);
    logmodel.message = JSON.stringify(logmodel.message);
    //var token = "Bearer " + localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
       // Authorization: token,
      }),
    };
    this.http.post<any>(this.url + "error", logmodel, httpOptions).subscribe(() => {
      console.log("log error success")
    }, (error) => {
      console.log("log error failed with error" + error.message);
    });
  }
  Information(logmodel: LogI) {
    logmodel.data = JSON.stringify(logmodel.data);
    logmodel.message = JSON.stringify(logmodel.message);
    //var token = "Bearer " + localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        //Authorization: token,
      }),
    };
    this.http.post<any>(this.url + "info", logmodel, httpOptions).subscribe(() => {
      console.log("log info success")
    }, (error) => {
      console.log("log info failed with error" + error.message);
    });
  }
}


