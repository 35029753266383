import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JwtDecoderService {
  decodeToken(token: string) {
    try {
      const decoded = jwt_decode(token);
      return decoded;
    } catch (error) {
      console.error('Invalid token', error);
      return null;
    }
  }
}