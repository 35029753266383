import { environment } from "src/environments/environment";
import { JwtDecoderService } from "../Services/jwt-decoder.service";

interface DecodedToken {
    userId: string;
    role: string;
    FullName: string;
    OrgName: string;
    showArchive: string;
    OrgId: string;
    SuperUserOrgs: string[];
}

export class UserLoginI {
    token: string;
    userId: string;
    role: string;
    fullName: string;
    orgName: string;
    showArchive: boolean;
    // checkQuantitiy: boolean;
    constructor(private jwtDecoderService: JwtDecoderService, private cookieService: any) {
        this.token = this.getAuthToken();
        // this.userId = localStorage.getItem("userId");
        // this.role = localStorage.getItem("role");
        // this.fullName = localStorage.getItem("fullName");
        // this.orgName = localStorage.getItem("orgName");
        // this.showArchive = (localStorage.getItem("showArchive") === 'true')
        // this.checkQuantitiy = (localStorage.getItem("checkQuantitiy") === 'true')
        const decodedToken = this.jwtDecoderService.decodeToken(this.token) as DecodedToken;
        if (decodedToken) {
            this.userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
            this.role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            this.fullName = decodedToken.FullName;
            this.orgName = decodedToken.OrgName;
            if (decodedToken.OrgId == environment.ELBIT_ID || this.role == 'ADMIN' || this.role == 'ADMINJUNIOR' || this.role == 'SUPER' && decodedToken.SuperUserOrgs.includes(environment.ELBIT_ID)) {
                this.showArchive = true;
            } else {
                this.showArchive = false;
            }
        } else {
            // Handle invalid token case
            this.userId = null;
            this.role = null;
            this.fullName = null;
            this.orgName = null;
            this.showArchive = false;
        }
    }
    clear() {
        // localStorage.removeItem("token");
        this.cookieService.delete('token');
        // localStorage.removeItem("userId");
        // localStorage.removeItem("role");
        // localStorage.removeItem("fullName");
        // localStorage.removeItem("orgName");
        // localStorage.removeItem("showArchive");
        // localStorage.removeItem("checkQuantitiy");
    }

    getAuthToken(): string | null {
        return this.cookieService.get('token');
    }


}
