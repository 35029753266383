import { Component, HostListener, OnInit,Inject } from '@angular/core';
import {  MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(  private dialogRef: MatDialogRef<MessageComponent>, @Inject(MAT_DIALOG_DATA) public myData: any) { }

  ngOnInit() {
  }
  @HostListener("window:keyup.esc") onKeyUp() {
    this.dialogRef.close(false);
  }
  close(result){
    this.dialogRef.close(result); 
  }
}
