import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// import { LoginComponent } from "./components/user/login/login.component";
// import { ChatComponent } from "./components/chat/chat.component";
// import { MissionsListComponent } from "./components/missions/missions-list/missions-list.component";
// import { OrgsListComponent } from "./components/orgs/orgs-list/orgs-list.component";
// //import { UsersListComponent } from "./components/users/users-list/users-list.component";
import { AuthService } from "./Services/auth.service";
// import { RegistrationComponent } from "./components/user/registration/registration.component";
// import { ForgotPasswordComponent } from "./components/user/forgot-password/forgot-password.component";
// import { ResetPasswordComponent } from "./components/user/reset-password/reset-password.component";
// import { NewOrderComponent } from "./components/new-order/new-order.component";
// import { ArchiveComponent } from "./components/archive/archive.component";
// //import { FileManagerComponent } from "./components/files/file-manager/file-manager.component";
// import { StaticReportsComponent } from "./components/static-reports/static-reports.component";
// //import { ReportGeneratorComponent } from './components/report-generator/report-generator.component';
// //import { ReportViewerComponent } from './components/report-viewer/report-viewer.component';
// import { NewUserComponent } from './components/user/new-user/new-user.component';
// import { HistoryComponent } from './components/missions/history/history.component';
// import { UserDeclarationComponent } from "./components/users/user-declaration/user-declaration.component";
// import { WorkersComponent } from "./components/workers/workers.component";
// import { OrderComponent } from "./components/order/order.component";
// import { MainComponent } from "./components/main/main.component";
// 
const routes: Routes = [
 
  // {path:"**",component:MainComponent, canActivate: [AuthService]},
  // { path: "", loadChildren: () => import('../app/components/user/login/login.module').then(module => module.LoginModule) },
  { path: "login", loadChildren: () => import('../app/components/user/login/login.module').then(module => module.LoginModule) },
  { path: '', loadChildren: () => import('../app/components/main/main.module').then(module => module.MainModule), canActivate: [AuthService] },
  // { path: "", component: MissionsListComponent, canActivate: [AuthService] },
  // {
  //   path: "home",
  //   component: MissionsListComponent,
  //   canActivate: [AuthService]
  // },
  // {
  //   path: "history",
  //   component: HistoryComponent,
  //   canActivate: [AuthService]
  // },
  // { path: "login", component: LoginComponent },
  // { path: "new-user", component: NewUserComponent , canActivate: [AuthService]},
  // { path: "register-user", component: RegistrationComponent },
  // { path: "forgot-password", component: ForgotPasswordComponent },
  // { path: "users", component: UserDeclarationComponent, canActivate: [AuthService] },
  // { path: "orgs", component: OrgsListComponent, canActivate: [AuthService] },
  // {
  //   path: "archive",
  //   component: ArchiveComponent,
  //   canActivate: [AuthService]
  // },
  // {
  //   path: "new-order",
  //   component: NewOrderComponent,
  //   canActivate: [AuthService]
  // },
  // {
  //   path: "order",
  //   component: OrderComponent,
  //   canActivate: [AuthService]
  // },
  // {
  //   path: "static-reports",
  //   component: StaticReportsComponent,
  //   canActivate: [AuthService]
  // },
  // {
  //   path: "workers",
  //   component: WorkersComponent,
  //   canActivate: [AuthService]
  // },
  // {
  //   path: "reset-password/:userId/:code",
  //   component: ResetPasswordComponent,
  //   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
